<template>
	<el-dialog title="添加队列" :visible.sync="visible" :before-close="handleClose" width="800px">
		<el-form
			:model="dataForm"
			:rules="rules"
			ref="dataForm"
			label-width="100px"
			class="dialog-form-con"
		>
			<el-form-item label="队列名称" :label-width="formLabelWidth" prop="name">
				<el-input style="width:350px" v-model="dataForm.name" placeholder="请输入" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="队列负责人" :label-width="formLabelWidth" prop="doctorId">
				<el-select style="width:350px" v-model="dataForm.doctorId" placeholder="请选择">
					<el-option
						v-for="item in teamsMemberList"
						:label="item.docterName"
						:value="item.docterId"
						:key="item.docterId"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="可见范围" :label-width="formLabelWidth" prop="type">
				<el-select style="width:350px" v-model="dataForm.type" placeholder="请选择">
					<el-option label="仅自己" value="1"></el-option>
					<el-option label="仅团队成员" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="排序权重" :label-width="formLabelWidth" prop="weight">
				<el-input
					style="width:350px"
					v-model="dataForm.weight"
					placeholder="默认为0，值越大，排序越靠前"
					autocomplete="off"
				></el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm">确 定</el-button>
			<el-button @click="handleClose">取 消</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { getVisitApi, postVisitInfoAddApi } from "@/api/visit";
import qs from "qs";
export default {
  props: {
    state: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      teamsMemberList: [],
      flag: true,
      visible: true,
      radio: 7,
      defaultProps: {
        children: "children",
        label: "label",
      },
      dataForm: {
        name: "",

        doctorId: "",

        type: "",

        weight: "0",

        teamsId: "",
      },
      formLabelWidth: "120px",
      rules: {
        name: [
          {
            required: true,
            message: "请输入队列名称",
            trigger: "blur",
          },
        ],
        weight: [
          {
            required: true,
            message: "请输入排序权重",
            trigger: "blur",
          },
        ],

        type: [
          {
            required: true,
            message: "请选择可见范围",
            trigger: "blur",
          },
        ],
        doctorId: [
          {
            required: true,
            message: "请选择队列负责人",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    this.getTeamsMember();
  },

  methods: {
    submitForm(val) {
      let _this = this;
      let params = this.dataForm;
      params.teamsId = JSON.parse(localStorage.currentTeam).id;

      // return
      _this.$refs["dataForm"].validate((valid) => {
        console.log("123", valid);
        if (valid) {
          if (_this.flag) {
            _this.flag = false;
            // return;
            _this
              .$httpAes({
                url: _this.$httpAes.adornUrl(
                  params.id ? "/cohortStudy/update" : "/cohortStudy/add"
                ),
                method: "post",
                data: params,
              })
              .then(({ data }) => {
                if (data.status) {
                  _this.$message.success(data.msg);
                  this.$emit("refreshDataList");
                  this.handleClose();
                } else {
                  _this.$message.error(data.msg);
                  _this.flag = true;
                }
              });
          }
        }
      });
    },
    async handleInitData() {
      const list = await getVisitApi();
      console.log("visit", list.data);
    },
    async handleClose() {
      this.$emit("closeDialog");
    },
    getTeamsMember() {
      let _this = this;
      let params = {
        intParam2: 1,
        intParam1: 9999,

        stringParam5: JSON.parse(localStorage.currentTeam).id,
        stringParam6: 1
      };

      _this
        .$httpAes({
          url: _this.$httpAes.adornUrl("/teamsMember/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          _this.teamsMemberList = data.data;
        });
    },
  },
};
</script>

